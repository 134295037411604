<template>
  <a-modal
    :visible.sync="show"
    title="选择优惠券"
    destroyOnClose
    width="800px"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item class="ml-2" prop="coupon_name" label="优惠券名称">
              <a-input allowClear v-model="searchForm.coupon_name" placeholder="优惠券名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item style="margin-left:20px;">
              <a-button @click="handlerSearch" type="primary">搜索</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table
      ref="goodsTable"
      :columnsData="columns"
      rowKey="coupon_id"
      :row-selection="rowSelection"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
      :customHeight="400"
    >
      <template #number="{ record }">
        <a-input-number @change="onChangeNumber" v-model="record.number" :min="1"></a-input-number>
      </template>
      <div slot="footer">
        已选<span class="text-red-500">{{ rowSelection.selectedRowKeys.length }}</span>项
      </div>
    </base-table>
  </a-modal>
</template>

<script>
import { getCouponList } from "@/api/activity/full-reduce"
import _ from "lodash"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    selectKeys: {
      type: Array,
    },

    selectRows: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      wrapperCol: { span: 16 },
      labelCol: { span: 8 },
      searchForm: {
        coupon_name: "",
        page: 1,
        page_count: 10,
      },
      columns: [
        {
          title: "优惠券名称",
          dataIndex: "coupon_name",
          align: "center",
        },
        {
          title: "有效期开始",
          dataIndex: "start_date",
          align: "center",
          width: 150,
        },
        {
          title: "有效期结束",
          dataIndex: "end_date",
          align: "center",
          width: 150,
        },
        // {
        //   title: "数量",
        //   dataIndex: "number",
        //   align: "center",
        //   width: 100,
        //   slots: {
        //     customRender: "number",
        //   },
        // },
      ],
      tableData: [],
      total: 0,
      rows: [],
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.coupon_id)
            this.rows.push(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.coupon_id
            )
            this.rows = this.rows.filter(
              (el) => el.coupon_id != record.coupon_id
            )
          }
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = this.selectedRows
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.coupon_id
            )
            this.rows = selectedRows
          } else {
            this.rowSelection.selectedRowKeys = []
            this.rows = []
          }
        },
        getCheckboxProps: (record) => {
          return {
            props: {
              defaultChecked: this.selectKeys.includes(record.coupon_id)
            }
          };
        }
      },
    }
  },
  mounted() {
    this.rowSelection.selectedRowKeys = this.selectKeys
    this.initData()
    this.rows =  _.cloneDeep(this.selectRows)
  },
  methods: {
    async initData() {
      const { data, code } = await getCouponList(this.searchForm)
      if (code === 0) {
        // data.list.map(el=>{
        //   const item = this.selectRows.find(row=>row.coupon_id == el.coupon_id)
        //   el.number = item && item.number || 1
        // })

        this.tableData = data.list.map(el=>{
          el.start_date = el.start_time.split(" ")[0]
          el.end_date = el.end_time.split(" ")[0]
          return el
        })
        this.total = data.total_count
      }
    },

    onChangeNumber(value){
      console.log("onChangeNumber", value)
    },
    // 保存
    handleOk() {
      this.$emit("update:show", false)
      this.$emit("ok", this.rows)
    },
    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>