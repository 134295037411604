import ajax from '@/utils/ajax.js'

// 套餐列表
export function getRechargeList(params) {
  return ajax.post('/RechargePackage/getList',params)
}

// 获取详情
export function getRechargeDetail(params) {
  return ajax.post('/RechargePackage/detail',params)
}

// 更新
export function updateRecharge(params) {
  return ajax.post('/RechargePackage/update',params)
}

// 删除
export function deleteRecharge(params) {
  return ajax.post('/RechargePackage/updateStatus',params)
}

// 添加
export function addRecharge(params) {
  return ajax.post('/RechargePackage/add',params)
}



// --------------------- 钱包礼品卡

/**
 * 获取钱包礼品卡分类
 * @param {*} params 
 * @returns 
 */
export function getGiftTypeList(params) {
  return ajax.post('/RechargeCard/getTypeList', params)
}

/**
 * 编辑钱包礼品卡分类
 * @param {*} params 
 * @returns 
 */
export function updateGiftType(params) {
  return ajax.post('/RechargeCard/editType', params)
}

/**
 * 获取钱包礼品卡列表
 * @param {*} params 
 * @returns 
 */
export function getGiftCardList(params) {
  return ajax.post('/RechargeCard/getCardList', params)
}

/**
 * 编辑钱包礼品卡
 * @param {*} params 
 * @returns 
 */
export function updateGiftCard(params) {
  return ajax.post('/RechargeCard/editCard', params)
}

